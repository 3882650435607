.ProgressBox {
    width: 100vw;
    height: 100vh;
    background: url('/public/assets/index/newLogin.png') no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
}

.backBtn {
    position: absolute;
    bottom: 10px;
    width: 200px;
    font-size: 20px;
    height: 40px;
    background-color: #00b578;
    padding: 10px;
    color: #fff;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.adm-result {
    background: none;
    backdrop-filter: blur(5px);
    height: 100vh;
}

.adm-result-title {
    color: #fff;
}

.ploading {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: bold;
}

.Progress {
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.adm-error-block-description-title {
    display: none;
}

.adm-error-block {
    width: 300px;
}

.adm-error-block-full-page {
    padding-top: 0;
}